/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

// Future Developer Note:
// Any imports you load here go into the default bundle.
// In other words, you'll make the time bundles boot longer and larger.
// The goal with this file is to keep the imports to a minimum
// and NOT import any app-specific code here, because we may redirect
// and not boot the app. We use a dynamic import() below to actually
// load the app - this lets webpack tree-shake and make a separate
// physical bundle for this file and for the app bundle, so it can
// just load this file without loading the app, saving time and bandwidth.
import React from 'react';
import ReactDOM from 'react-dom';

// Need capacitor to detect what type of platform (web/ios/android) we're on
import { Capacitor } from '@capacitor/core';
import { Http } from '@capacitor-community/http';

// Utilities and app config
import styled from 'styled-components';
import { later } from 'shared/utils/later';
import AppConfig from 'shared/config-public';

// This is the ONLY app component we import and we "know"
// it has limited child imports so it is light and can render
// quickly while the rest of the app boots.
import { BootAnim } from './BootAnim';

// Simple check routine to use the native HTTP plugin to
// check the given URL for a 2xx or 3xx status code.
// If 2x or 3x found, all good. If anything else - bad.
// Also will timeout after 2000ms (default)
async function checkUrl(url, timeout = 2000) {
	return new Promise(async (resolve) => {
		let done = false;
		setTimeout(() => {
			if (!done) {
				resolve({
					success: false,
					message: `Timed out requesting ${url} after ${timeout} milliseconds`,
				});
			}
		}, timeout);

		// const fullResponse = await Http.get({ url });
		const fullResponse = await fetch(url).catch((error) => {
			return { error, status: 500 };
		});

		done = true;

		const { status, error } = fullResponse;

		const responseCodeClass = `${status}`.slice(0, 1);
		console.log(
			`[Check URL (${url})] fetch response:`,
			responseCodeClass,
			fullResponse,
		);

		resolve({
			success: !!['2', '3'].includes(responseCodeClass),
			message: error?.message || `HTTP ${status} response`,
		});
	});
}

const StyledPageContainer = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	position: relative;

	color: #6a2a5b;
	background: white;

	background-color: white;
	box-shadow: 0 0 0 6px #b5479b, 0 1px 2px 0 #6a2a5b;
	max-height: 650px;
	max-width: 477px;

	overflow: auto;

	border-radius: 1rem;

	max-width: 50%;
	margin: 3rem 1rem 3rem;

	padding: 40px;

	text-align: center;

	&.loading {
		transform: translateX(-300%);
	}

	.error {
		margin: 1rem;
		font-weight: bold;
	}

	@media screen and (max-width: 540px) {
		max-width: 100%;
	}

	.message {
		font-size: 65%;
	}
`;

function OfflineMessage({ message }) {
	return (
		<StyledPageContainer>
			<h1>You're offline</h1>
			{!!message && <div className="message">{message}</div>}

			<h4>
				Please check your internet connection and then re-open the
				app
			</h4>
		</StyledPageContainer>
	);
}

// Dedicated render function so we can override to mock for testing if desired
let renderComponentInDom = (Component) => {
	renderComponentInDom.root.render(<Component />);
	// ReactDOM.render(<Component />, document.getElementById('root'));
};

// Dedicated accessor for mocking if needed
let getCurrentPlatform = () => Capacitor.getPlatform();

/**
 * This is the main "exit" point for the localAppShim.js, it returns
 * control of the current web view (and DOM) to our App.js code.
 *
 * Think of the flow as going:
 * ```
 * localAppShim > redirectOrBoot > bootLocalApp
 * ```
 *
 * Where `redirectOrBoot`, if not redirecting, calls `bootLocalApp`
 * at a couple different exit points to start booting the actual app.
 *
 * This takes advantage of ES6's dynamic `import()`s and webpacks
 * tree-shaking/bundle-splitting to delay ANY parsing or execution of
 * the rest of the app until we call this function.
 *
 * This ensures the update code (this file) has complete control over the
 * DOM and does not have to worry about setting some flag to stop
 * the app from rendering while still updating.
 *
 * Using the import() also allows us to instead render a lightweight
 * `BootAnim` while waiting on heuristic decisions
 */
async function bootLocalApp() {
	const startTime = Date.now();
	// console.log(`localAppShim: Booting local bundle from App.js..`);

	let error;
	const { default: App, ...props } =
		(await import('./App').catch((ex) => {
			error = ex;
		})) || {};

	if (!App) {
		// Basically an unrecoverable error
		console.error(
			`Unrecoverable Error importing App, the application will fail:`,
			{
				message: error?.message,
				stack: error?.stack,
				props,
			},
		);

		// Chunk errors usually due to updates, so just reload index to get latest chunk index
		if (`${error?.message}`.includes('chunk')) {
			console.warn(
				`Reloading app automatically due to chunk error:`,
				error.message,
			);
			window.location.reload();
		} else {
			renderComponentInDom(() => (
				<code className="break-all-safe text-red-800">
					{error?.message}
				</code>
			));
		}
		return;
	}

	const endTime = Date.now();
	const loadTime = endTime - startTime;
	if (AppConfig.buildEnv !== 'dev') {
		console.log(
			`localAppShim: App.js loaded, mounting on DOM and returning control to the app. (Bundle loaded in ${loadTime} ms)`,
		);
	}

	// Mount <App> in the DOM and the app will continue it's normal boot from there
	renderComponentInDom(App);
}

/**
 * This is the core update heuristic, it primarily deals with
 * determining the proper remote URL to use and then determines if the
 * device has network access and if so, redirects the web view to that
 * new remote URL. There's far more nuance to it, but you can read
 * the code yourself - that's the big picture intent, anyway.
 */
async function redirectOrBoot() {
	// Just stringify for ease of use below
	const currentUrl = `${window.location.href}`;

	// Grab configs from app.config.js
	let { frontendV2Root: remoteBundleUrl } = AppConfig;

	// For Android, we've already updated capacitor.config.json to ALLOW the vayavalet.io domain.
	// However, iOS was submitted for review before that domain was added, so we need to use the
	// admin.vayadriving.com domain for iOS since the bundle included in that binary
	// has a localAppShim.js that will route to the admin.vayadriving.com domain.
	// let remoteBundleUrl =
	// 	getCurrentPlatform() === 'android'
	// 		? AppConfig.frontendValetApp // should be app.vayavalet.io
	// 		: AppConfig.frontendV2Root; // should be admin.vayadriving.com

	// For building a test copy locally
	if (process.env.REACT_APP_LOCAL_NGROK_URL) {
		remoteBundleUrl = process.env.REACT_APP_LOCAL_NGROK_URL;
	}

	// onlineCheckUrl is used to check online status - if we succeed in fetching
	// this URL in a reasonable amount of time, we assume we're online
	// and that the remote bundle is (likely) newer, so we can redirect
	// to remote bundle without booting/mounting the local bundle
	const onlineCheckUrl = `${remoteBundleUrl}/index.html`;

	// Stop from looping - if currentUrl is already pointed at remoteBundleUrl,
	// then boot the current bundle
	if (
		currentUrl.includes(remoteBundleUrl) ||
		// React dev server for testing, because in dev, remoteBundleUrl is tunnel (ngrok),
		// so it wouldn't match localhost (.dev.frontend)
		currentUrl.includes('.ngrok.io')
	) {
		console.log(
			`localAppShim: Running on remote-ish URL, not checking further`,
			{
				currentUrl,
				remoteBundleUrl,
			},
		);
		bootLocalApp();
		return;
	}

	console.log(`localAppShim: resolved urls:`, {
		onlineCheckUrl,
		remoteBundleUrl,
	});

	if (!remoteBundleUrl) {
		// This should never happen, but guard against weird errors if onlineCheckUrl
		// is falsey by showing our own error so we have more context for debugging if needed
		console.error(
			`AppUpdateUrl: no 'clientHost' in AppConfig to check for online status, assuming NOT online and booting local bundle`,
		);
		bootLocalApp();
		return;
	}

	// If we have a URL, means we have a valid env selection
	// and we are NOT running on a remote URL, so check to see if we're online
	// so we can redirect
	const onlineCheckUrlWithCacheBust = `${onlineCheckUrl}?_=${Date.now()}`;

	// Date is present to do cache-busting on load
	// Key '_updatedAt' is not presently used at this moment, other than cache-bust-holder
	const redirectUrl = `${remoteBundleUrl}?_updatedAt=${Date.now()}`;

	// For debugging...
	const ignoreUpdatesFlag = `${currentUrl}`.includes('_ignoreUpdates');

	// This timeout is indeed possible on slow networks or busy booting.
	// If we fail here, assume offline and don't redirect to remote
	const { success: urlCheckSuccess, message: urlCheckMessage } =
		await checkUrl(onlineCheckUrlWithCacheBust);

	// const urlCheckSuccess = false;
	// const urlCheckMessage = 'Testing';

	// If error (or timeout), log and render an error message in the DOM
	if (!urlCheckSuccess) {
		// Failure fetching, might be offline, can't do anything in the MP if offline,
		// so just render error messages
		console.error(
			`localAppShim: Error fetching ${onlineCheckUrlWithCacheBust}:`,
			{
				message: urlCheckMessage,
			},
		);

		renderComponentInDom(() => (
			<OfflineMessage message={urlCheckMessage} />
		));
		return;
	}

	// We must have set this flag for debugging, so don't actually redirect so we
	// can inspect console output
	if (ignoreUpdatesFlag) {
		console.log(
			`index.updates: found _ignoreUpdates in the URL, stopping and running app`,
			{
				currentUrl,
				remoteBundleUrl,
				redirectUrl,
				ignoreUpdatesFlag,
			},
		);

		bootLocalApp();
		return;
	}

	console.log(
		`localAppShim: Online and appear to not be up-to-date, so going to redirect below...`,
		{
			currentUrl,
			remoteBundleUrl,
			redirectUrl,
			ignoreUpdatesFlag,
		},
	);

	// Exit the app and reload.
	window.location.href = redirectUrl;
}

export default async function localAppShim({
	root,
	disableLater = false,
}) {
	renderComponentInDom.root = root;
	renderComponentInDom(BootAnim);

	// Move out of event loop so boot anim can render before we do other things
	const internalUpdate = async () => {
		// // Forcing to boot local bundle for Android testing
		// if (Date.now()) {
		// 	await bootLocalApp();
		// 	return;
		// }

		if (getCurrentPlatform() === 'web') {
			// If not running in a native context, nothing to do,
			// so just boot current bundle. Await to ensure
			// any thrown errors caught and logged by later()
			await bootLocalApp();
			return;
		}

		// Execute our heuristic code. Await to ensure
		// any thrown errors caught and logged by later()
		await redirectOrBoot();
	};

	if (disableLater) {
		await internalUpdate();
		return;
	}

	later(internalUpdate);
}
