/* eslint-disable no-unused-vars */
import path from 'path'; // TODO: If we use this client-side, will this be okay? Not yet tested
import Logger, { isNode } from './IsomorphicLogger';
import { StackTraceUtil } from './Logger';
import { defer } from './defer';
import exponentialDelayFactory from './exponentialDelay';

// Timers for use with later
const laterTimers = {};

// For awaiting all promises in scripts
let laterWaitList = [];

/**
 * Traps and handles errors that occur during asynchronous operations. Exporting this internal trap so we can use the trapping logic apart from the later delay, for example in cron jobs.
 *
 * @param {Function|Promise} callbackOrPromise - The callback function or promise to be executed.
 * @param {Object} options - The options for error handling.
 * @param {string} [options.laterStack=new Error('stack trace please').stack] - The stack trace to be used for logging purposes.
 * @param {Logger} [options.logger=Logger] - The logger instance to be used for error logging.
 * @param {boolean} [options.disableLoggerAlert=false] - Determines whether to disable the logger alert.
 * @param {string} [options.outerMethodName=undefined] - The name of the outer method where the error occurred.
 * @param {string} [options.alertLabel=undefined] - The label to be used for the logger alert, defaults to a string with 'AsyncErrorTrap-CaughtException-' followed by the outer method name.
 *
 * @returns {Promise} - A promise that resolves with the result of the callback function or promise, or rejects with the error.
 *
 * @example
 * // Using a callback function
 * const result = await trapAsyncErrors(() => {
 *   // Asynchronous code here
 * }, {
 *   logger,
 *   // other options here, see above
 * });
 *
 * // Using a promise
 * const result = await trapAsyncErrors(somePromise);
 */
export const trapAsyncErrors = async (
	callbackOrPromise,
	{
		laterStack = new Error('stack trace please').stack,
		logger = Logger,
		disableLoggerAlert = false,
		outerMethodName = undefined,
		alertLabel = undefined,
	} = {},
) => {
	// Get location (outside of this file) where the trapAsyncErrors call originated
	let filepath;
	let line;
	let trace = new Error('StackTraceUtil').stack; // StackTraceUtil.get();

	if (!Array.isArray(trace) && typeof trace === 'string') {
		const list = trace.split('\n');
		const match =
			list.find(
				(test) => test.trim().startsWith('at') && !test.includes(__filename),
			) || '<unknown>:<unknown>';

		const idx1 = match?.indexOf('(');
		const idx2 = match?.indexOf(')');
		const fileAndLine = match.substring(idx1 + 1, idx2 - 1);
		[filepath, line] = fileAndLine.split(':');

		// console.log(`string trace debug:`, {
		// 	list,
		// 	ourFileString,
		// 	match,
		// 	idx1,
		// 	idx2,
		// 	fileAndLine,
		// });
	} else {
		const caller = trace.find(
			(data) =>
				data && data.getFileName && !data.getFileName().includes(__filename),
		);
		filepath = caller.getFileName();
		line = caller.getLineNumber();
	}
	let file = path.basename(filepath || '');

	// index.js is not helpful, so replace with containing folder name
	if (file === 'index.js') {
		file = `${path.basename(filepath.replace(/\/index.js$/, ''))}/index.js`;
	}

	// Build short string for file location and line number
	const codeLocation = `${file}:${line}`;

	// // ONLY FOR TESTING
	// // eslint-disable-next-line no-console
	// console.log(`>>> trapAsyncErrors/codeLocation`, codeLocation);

	// If user didn't provide informative details, we'll try to help
	// by using the code location for the alert label that goes to slack
	if (!outerMethodName && !alertLabel) {
		// eslint-disable-next-line no-param-reassign
		alertLabel = `${codeLocation}/AsyncErrorTrap`;
	}

	let result;
	try {
		const promise =
			typeof callbackOrPromise === 'function'
				? callbackOrPromise()
				: callbackOrPromise;

		if (promise && typeof promise.catch === 'function') {
			result = await promise.catch((ex) => {
				result = ex;

				const context = {
					message: ex?.message,
					errorStack: ex?.stack,
					laterStack,
					originalException: ex,
				};

				logger.error(
					`Trapped error in promise given ${
						outerMethodName ? `to ${outerMethodName}` : `at ${codeLocation}`
					}:`,
					context,
				);
				if (!isNode) {
					logger.error(ex);
				}

				// .alert is only relevant on the server
				if (isNode) {
					Logger.alert(
						alertLabel || `AsyncErrorTrap-PromiseRejected-${outerMethodName}`,
						context,
					);
				}
			});
		}
	} catch (ex) {
		result = ex;

		const context = {
			message: ex?.message,
			errorStack: ex?.stack,
			laterStack,
			originalException: ex,
		};

		// console.error(`\n\n>>>odd logger`, logger, new Error('odd from').stack);
		logger.error(
			`Trapped error caught by trapAsyncErrors() in promise given ${
				outerMethodName ? `to ${outerMethodName}` : `at ${codeLocation}`
			}:`,
			context,
		);

		// .alert is only relevant on the server
		if (isNode && !disableLoggerAlert) {
			Logger.alert(
				alertLabel || `AsyncErrorTrap-CaughtException-${outerMethodName}`,
				context,
			);
		}
	}

	return result;
};

// Arbitrary delay to move 'later' further out from UX-relevant interactions
// const DelayFactory = () => {
// 	return 100 + Math.ceil(Math.random() * 200);
// };

const DelayFactory = exponentialDelayFactory({
	initialDelay: 100,
	maxDelay: 3333,
	multiplier: 1.125,
});

/**
 * Simple utility to move a given promise out of the event loop.
 * Useful for when you don't care about the return value, but still want to catch errors. This will
 * .catch() errors and log using the given (or default) Logger.
 *
 * @param {function|Promise} callbackOrPromise Function to call or promise to await. Function is assumed async, and as such, assumed to return a promise which will be awaited.
 * @param {Object} options (optional)
 * @param {Logger} options.logger (default: `Logger`) Logger instance to use for error logging
 * @param {number} options.delay (default: some non-zero number) Delay to use before awaiting the promise/calling the callback. Delay of 0 just moves it to the next event loop.
 * @param {boolean} options.immediate (default: `false`) If true, does NOT delay till next tick, just wraps the `callbackOrPromise` with error catching and returns an `await`-able value
 */
export const later = (callbackOrPromise, options) => {
	const { stack: laterStack } = new Error('later called from...');

	const finished = defer();

	const factoryDelay = DelayFactory();

	let {
		logger = Logger,
		delay = factoryDelay,
		key,
		immediate = false,
		debugDelay = false,
	} = typeof options === 'number' ? { delay: options } : options || {};

	if (laterTimers[key]) {
		clearTimeout(laterTimers[key]);
	}

	if (immediate) {
		return trapAsyncErrors(callbackOrPromise, {
			outerMethodName: 'later',
			laterStack,
			logger,
		});
	}

	if (debugDelay) {
		logger.debug(` > Using delay: `, delay);
	}

	const timerId = setTimeout(async () => {
		await trapAsyncErrors(callbackOrPromise, { laterStack, logger });
		finished.resolve();

		// Lower the accumulated delay pressure, otherwise we'll always stay at the highest
		// delay after a few later() calls
		DelayFactory.reduce();

		laterWaitList = laterWaitList.filter((x) => x !== finished);
	}, delay);

	if (key) {
		laterTimers[key] = timerId;
	}

	laterWaitList.push(finished);

	return Promise.resolve(finished);
};

export const waitForAllLater = () => Promise.all(laterWaitList);

/**
 * Returns a function that wraps later() and injects the logger
 * @param {Logger} logger
 * @returns {Function} later() with the given logger injected into the props
 */
export const getLaterWithLogger = (logger) => (callback, options) =>
	later(callback, { ...options, logger });
